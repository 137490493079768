@keyframes circle-loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fill-rect {
    0%, 50% {
        height: 0;
    }

    100% {
        height: inherit;
    }
}

@keyframes momentum {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@keyframes rect-rotate {
    0% {
        transform: rotate(0);
    }

    50%, 100% {
        transform: rotate(360deg);
    }
}

@keyframes recursive-circle {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}
