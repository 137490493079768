.centrize {
    display: table !important;
    height: 100% !important;
    position: relative !important;
    table-layout: fixed !important;
}

.full-width {
    max-width: 100% !important;
    width: 100% !important;
}

.vertical-center {
    display: table-cell !important;
    vertical-align: middle !important;
}
