@import '../../scss/variables';
@import '../../scss/layout';
@import '../../scss/animations';

.preloader {
    &-popup {
        background: 0 0;
        height: 100vh;
        position: absolute;
    }

    .spinner {
        &.box-rotation {
            animation: rect-rotate 2s linear infinite;
            border: 2px solid $primary-color;
            display: inline-block;
            transform-origin: center center;

            &::after {
                animation: fill-rect 2s linear infinite;
                background: $primary-color;
                display: block;
                height: 0;
                height: 100%;
                opacity: .5;
                width: 100%;
            }
        }

        &.clock {
            border-radius: 50%;
            border: 2px solid $primary-color;

            &::before {
                animation: circle-loader 2s infinite linear;
                background: $primary-color;
                border-radius: 2px;
                content: '';
                height: calc(50% - 6px);
                left: calc(50% - 1.5px);
                position: absolute;
                top: 6px;
                transform-origin: 50% 100%;
                width: 3px;
            }
        }

        &.quantum-spinner {
            animation: momentum 2s linear infinite;
            border-radius: 50%;
            border: 2px solid rgba(0,0,0,0);
            border-top-color: $primary-color;
            transform-origin: center center;

            &::after {
                animation: momentum 2s linear infinite;
                border-radius: 50%;
                border: 2px solid rgba(0,0,0,0);
                border-top-color: $primary-color;
                filter: hue-rotate(6deg);
                height: calc(50px*0.6);
                left: calc(((50px*0.4)/2) - 2px);
                opacity: .3;
                top: calc(((50px*0.4)/2) - 2px);
                transform-origin: center center;
                width: calc(50px*0.6);
            }

            &::before {
                animation: momentum calc(2s*2) linear infinite;
                border-radius: 50%;
                border: 2px solid rgba(0,0,0,0);
                border-top-color: $primary-color;
                filter: hue-rotate(3deg);
                height: calc(50px*0.8);
                left: calc(((50px*0.2)/2) - 2px);
                opacity: .7;
                top: calc(((50px*0.2)/2) - 2px);
                transform-origin: center center;
                width: calc(50px*0.8);
            }
        }

        &.recursive-circle {
            animation: recursive-circle 2s linear infinite;
            border-radius: 50%;
            border: 2px solid $primary-color;
            border-top-color: transparent;
            color: $primary-color;
            display: inline-block;
            transform-origin: center center;

            &::after {
                animation: recursive-circle 1s linear infinite reverse;
                border-radius: 50%;
                border: 2px solid $primary-color;
                border-top-color: transparent;
                display: block;
                height: 50%;
                left: calc((50px/4) - 1px);
                top: calc((50px/4) - 1px);
                transform-origin: center center;
                width: 50%;
            }
        }

        &.simple-circle {
            animation: circle-loader 2s infinite ease-out;
            border-radius: 50%;
            border-right-color: $primary-color;
            border: 2px solid $border-color;
            transform-origin: center center;
        }
    }
}

.preloader,
.preloader-popup {
    .spinner {
        animation: sk-bounce 2s infinite ease-in-out;
        box-sizing: border-box;
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        height: 50px;
        position: relative;
        transition: opacity 0.8s ease-in-out;
        width: 50px;

        &--hidden {
            opacity: 0;
        }

        &::after,
        &::before {
            box-sizing: border-box;
            content: '';
            flex-grow: 0;
            flex-shrink: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &.default-circle {
            &::after,
            &::before {
                animation: sk-bounce 2s infinite ease-in-out;
                background: $primary-color;
                border-radius: 50%;
                opacity: 1;
                transition: opacity 1s ease;
            }

            &::after {
                animation-delay: -1s;
                opacity: .1;
            }
        }

        .double-bounce1,
        .double-bounce2 {
            animation: sk-bounce 2s infinite ease-in-out;
            background: $primary-color;
            border-radius: 50%;
            height: 100%;
            left: 0;
            opacity: 1;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .double-bounce2 {
            animation-delay: -1s;
            opacity: .1;
        }
    }
}

.preloader,
.preloader-popup,
.preload-svg {
    background: $background-color;
    height: 100%;
    left: 0;
    opacity: 1;
    position: fixed;
    text-align: center;
    transition: opacity 3s ease-in-out, visibility 0s 3s;
    top: 0;
    visibility: visible;
    width: 100%;
    z-index: 1000;
    
    &--hidden {
        opacity: 0;
        transition: opacity 3s ease-in-out, visibility 0s 3s;
        visibility: hidden;
    }
}
